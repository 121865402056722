<template>
  <div class="form-group">
    <div class="form-group-item">
      <div class="form-icon form-date">
        <Flatpickr
          :class="{
            'form-control form-icon-input form-date-input': true,
            'is-error': errorField[startField] || errors[errorStartField]
          }"
          :disabled="isDateTimeDisabled"
          name="startDate"
          v-model="startDate"
          :config="startConfig"
          @on-change="startChange"
        />
      </div>
    </div>
    <div class="form-group-item">
      〜
    </div>
    <div class="form-group-item">
      <div class="form-icon form-date">
        <Flatpickr
          :class="{
            'form-control form-icon-input form-date-input': true,
            'is-error': errorField[endField] || errors[errorEndField]
          }"
          :disabled="isDateTimeDisabled"
          name="endDate"
          v-model="endDate"
          :config="endConfig"
          @on-change="endChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
//flatpickr
//https://github.com/flatpickr/flatpickr
//https://www.npmjs.com/package/vue-flatpickr-component
import Flatpickr from 'vue-flatpickr-component';
import flatpickr from '@/mixins/plugin/flatpickr';
import { getFullDate } from '@/filters/date';
import error from '@/mixins/plugin/error';

export default {
  data: function() {
    return {
      startDate: '',
      endDate: '',
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    errorField: {
      type: Object,
      required: false,
      default: () => {},
    },
    errorStartField: {
      type: String,
      default: '',
    },
    errorEndField: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      required: true,
      default: '',
    },
    startField: {
      type: String,
      default: 'start',
    },
    endField: {
      type: String,
      default: 'end',
    },
    type: {
      type: String,
      default: 'date',
      validator(value) {
        return ['hourMinute', 'date', 'dateTime'].includes(value);
      }
    },
    isConfigNoMinutes: {
      type: Boolean,
      default: false,
    },
    isConfigTimeNow: {
      type: Boolean,
      default: false,
    },
    isDateTimeDisabled: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [flatpickr, error],
  components: { Flatpickr },
  computed: {
    isDateTimeConfig() {
      return this.type === 'dateTime';
    },
    isHourMinuteConfig() {
      return this.type === 'hourMinute';
    },
    startConfig() {
      if (this.isHourMinuteConfig) return this.config.startWithHourMinuteTime;
      return this.isDateTimeConfig ? this.config.startWithTime : this.config.start;
    },
    endConfig() {
      if (this.isHourMinuteConfig) return this.config.endWithHourMinuteTime
      return this.isDateTimeConfig ? this.config.endWithTime : this.config.end;
    }
  },
  watch: {
    'value.start': {
      handler(val) {
        this.startDate = val;
        if (val) {
          this.setStartDate(val);
        }
      },
      immediate: true,
    },
    'value.end': {
      handler(val) {
        this.endDate = val;
        if (val) {
          this.setEndDate(val);
        }
      },
      immediate: true,
    },
    'value.startDate'(val) {
      if (val) {
        this.setStartDate(val);
      }
    },
    'value.endDate'(val) {
      if (val) {
        this.setEndDate(val);
      }
    },
    startDate(val) {
      this.$emit('on-change', this.field, this.startField, val);
    },
    endDate(val) {
      this.$emit('on-change', this.field, this.endField, val);
    },
  },
  methods: {
    setStartDate(val) {
      this.startDate = val;
      const startDate = new Date(val);
      const now = new Date();
      if (this.handleHourMinute(val, this.endConfig, 'minTime')) return;
      if (!this.isConfigTimeNow) {
        now.setHours(0)
        now.setMinutes(0)
      }
      if (startDate.getTime() < now.getTime()) {
        if (!this.isConfigTimeNow) {
          startDate.setHours(0)
          startDate.setMinutes(0)
        }
        this.$set(this.startConfig, 'minDate', this.isDateTimeConfig ? getFullDate(startDate) : val);
      }
    },
    setEndDate(val) {
      this.endDate = val;
      const endDate = new Date(val).getTime();
      const now = Date.now();
      if (this.handleHourMinute(val, this.startConfig, 'maxTime')) return;
      if (endDate < now) {
        this.$set(this.endConfig, 'minDate', val);
      }
    }
  },
};
</script>
